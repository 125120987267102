import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import header_logo from "../images/EB-logo-word-only.png";

function Footer() {
  //   const [isExpanded, toggleExpansion] = useState(false);
  //   const { site } = useStaticQuery(graphql`
  //     query SiteTitleQuery {
  //       site {
  //         siteMetadata {
  //           title
  //         }
  //       }
  //     }
  //   `);

  return (
    <footer className="bg-blue-700">
      <nav className="flex justify-between max-w-4xl mx-auto p-4 md:p-8 text-sm">
        <p className="text-white">
          Created by{` `}
          <a
            className="font-bold no-underline text-white"
            href="https://bryant.io"
          >
            Taylor Bryant
          </a>
        </p>

        <p>
          <a
            className="font-bold no-underline text-white"
            href="https://github.com/taylorbryant/gatsby-starter-tailwind"
          >
            GitHub
          </a>
        </p>
      </nav>
    </footer>
  );
}

export default Footer;
