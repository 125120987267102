import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import header_logo from "../images/EB-logo-word-only.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div>
      <div className="application">
        {/* add by Kenta on 5 Dec 2019 */}
        <Helmet>
          <meta charSet="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        </Helmet>
      </div>
      <header className="bg-teal-700">
        <div className="flex flex-wrap items-center justify-between max-w-4xl mx-auto p-4 md:p-8">
          {/* our logo */}
          <Link className="flex items-center no-underline text-white" to="/">
            <img className="block w-1/4" src={header_logo} />
            <span className="font-bold text-xl tracking-tight">
              {site.siteMetadata.title}
            </span>
          </Link>
          {/* hamburget button */}
          <button
            className="block md:hidden border border-white flex items-center px-3 py-2 rounded text-white"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:block md:flex md:items-center w-full md:w-auto`}
          >
            {[
              {
                route: `/`,
                title: `Home`
              },
              {
                route: `/solutions`,
                title: `Solutions`
              },
              {
                route: `/team`,
                title: `Team`
              },
              {
                route: `/contact`,
                title: `Contact`
              },
              {
                route: `/demo`,
                title: `Get quote`
              }
            ].map(link => (
              <Link
                className="block md:inline-block mt-4 md:mt-0 md:ml-6 no-underline text-white"
                key={link.title}
                to={link.route}
              >
                {link.title}
              </Link>
            ))}
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
